<template>
    <div>
      <v-overlay :value="overlay" absolute style="z-index:999999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="pa-4">
        
        <v-toolbar flat color="white">
          <v-toolbar-title>Asiento contable :: {{datosAsiento.acctg_trans_id}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical> </v-divider>          
          
          <v-btn @click="guardarAsiento()" class="mx-4" dark small color="blue"><v-icon small>mdi-pencil</v-icon>Guardar</v-btn>
                   

        </v-toolbar>
       
        <v-form ref="formAsiento">
        <v-row class="my-0">            
            <v-col cols="6" class="my-0">
                <v-autocomplete  
                    :rules="requiredRule"
                    :readonly="lectura" 
                    label="Tipo de transacción" 
                    :items="tiposAsiento" 
                    item-value="acctg_trans_type_id" 
                    item-text="description" 
                    v-model="datosAsiento.acctg_trans_type_id" ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="my-0">
                <v-text-field :rules="requiredRule" :readonly="lectura" type="date" label="Fecha. transacción" v-model="datosAsiento.transaction_date" />
                
            </v-col>      
        </v-row>
               

        <v-row class="my-0">
            <v-col cols="12" class="my-0">
                <v-text-field 
                
                :readonly="lectura" label="Descripción" v-model="datosAsiento.description" />                
            </v-col>
        
        </v-row>

        <v-row class="my-0">
            <v-col cols="6" class="my-0">
                <v-text-field :rules="requiredRule" :readonly="lectura" label="Monto" v-model="datosAsiento.amount" />                
            </v-col>
        
        </v-row>

        <v-row class="my-0">
            <v-col cols="6" class="my-0">
                <v-autocomplete  
                    :rules="requiredRule"
                    :readonly="lectura" 
                    label="Debito " 
                    :items="cuentas" 
                    item-value="gl_account_id" 
                    item-text="nombre_cuenta" 
                    v-model="datosAsiento.gl_account_id_debito" ></v-autocomplete>
            </v-col>
        </v-row>

        <v-row class="my-0">
            <v-col cols="6" class="my-0">
                <v-autocomplete  
                    :rules="requiredRule"
                    :readonly="lectura" 
                    label="Crédito" 
                    :items="cuentas" 
                    item-value="gl_account_id" 
                    item-text="nombre_cuenta" 
                    v-model="datosAsiento.gl_account_id_credito" ></v-autocomplete>
            </v-col>
        </v-row>

        
        </v-form>
        <!-- <v-data-table :items-per-page="itemsPerPage" :items="listaAte" :headers="headersAte" hide-default-footer /> -->
    </v-card>
</div>
</template>


<script>

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
//import FormCajaComponent from '../contabilidad/FormCajaComponent'
import moment from 'moment'

export default {
    name: 'AsientoContableVista',
    props: {
        

    },
    components: {

    },
    data: () => ({
        cajaNum: "",
        cajaFecha: "",
        cajaEstado: "",
        cajaUsuario: "",
        headersAte: [
            { text: "Transaccion", value: "acctg_trans_id"},
			{ text: "Seq", value: "acctg_trans_entry_seq_id" },						
			{ text: "Cuenta", value: "cuenta_nombre" },			 
            { text: "Descripcion", value: "description" },			 
			{ text: "Monto", value: "amount" },
			{ text: "D/C", value: "debit_credit_flag" },
			
		],
        requiredRule: [(v) => !!v || "El campo es requerido"],
       
        listaAte: [],
        options: {},
        overlay: false,
        search: "",
        uid: "",
        datosAsiento: {},
        lectura:false,

        pageCount: 1,
        currentPage: 1,
        itemsPerPage: 20,
        tiposAsiento: [],
        cuentas: []


    }),
    computed: {
        ...mapState('master', ['loadingTable']),
        ...mapState('contabilidad', ['menu']),
        ...mapState('pos', ['menuPos']),
        ...mapGetters('access', ['conciliarCaja'])

    }
    , methods: {
        ...mapMutations('master', ['setUrl', 'setOverlay', 'setMenu', 'setLoadingTable']),
        ...mapActions('master', ['requestApi']),

        cargarDatos() {
            this.overlay = true
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    accion: "datosAsientoForm"                         
                }
            }).then(res => {                
                //console.log(res)
                this.tiposAsiento = res.data.detail.tipos_asiento;
                this.cuentas = res.data.detail.cuentas
                this.overlay = false
            }).catch(()=>{
                this.overlay = false
            });
        },
        cargarAsiento(id) {
            this.overlay = true
            this.setUrl('acctg-transaction/'+id)

            this.requestApi({
                method: 'GET',
                data: {
                                  
                }
            }).then(res => {                
                //console.log(res)
                this.datosAsiento = res.data.detail.acctg_trans;
                this.listaAte = res.data.detail.acctg_trans_entries;
                this.overlay = false
            }).catch(()=>{
                this.overlay = false
            });
        },
                
        
        handlePageChange(value) {
            this.currentPage = value
            
        },

        guardarAsiento() {

            if(!this.$refs.formAsiento.validate()) {
                return false
            }
            this.overlay = true
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    accion: this.datosAsiento.acctg_trans_id == undefined ? "crearAsientoSimple" : "modificarAsiento",
                    datosAsiento: this.datosAsiento                    
                }
            }).then(res => {                
                
                this.overlay = false
                // this.$router.push({
                //         path: "/asientoContableVista/" + res.data.detail.acctg_trans_id,
                //     });
                
                this.$router.push({
                        path: "/asientoContableForm/" + res.data.detail.acctg_trans_id,
                    });               
                
            }).catch(()=>{
                this.overlay = false
            });
        },

     
       

    },

    watch: {

        options: {
            handler() {
                //this.cargarAsiento()
            },
            deep: true,
        },
    },
    mounted() {        
        this.datosAsiento = {transaction_date: moment(new Date()).format('YYYY-MM-DD'), gl_fiscal_type_id:"ACTUAL" }
        this.cargarDatos();
        if(this.$route.params.acctgTransId != undefined) {
            this.cargarAsiento(this.$route.params.acctgTransId)
        }

    },



}
</script>